import { useIubendaStore } from "mars-iubenda-vite"
// import { useIubendaStore } from "~/store/iubenda"
import type { Pinia } from "pinia"

export default defineNuxtPlugin(({ $pinia }) => {
  return {
    provide: {
      iubendaStore: useIubendaStore($pinia as Pinia)
    }
  }
})
