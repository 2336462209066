<template>
  <div class="container">
    <div class="text-red-900 text-4xl mb-4 mt-16">{{ error.statusCode }}</div>
    <h1 v-if="error.statusCode === 404">Page not found</h1>
    <h1 v-else>An error occurred</h1>
    {{ error }}
    <ActionButton class="mt-8 mb-16" route="index" :bg="Colors.orange" :fg="Colors.white" value="Home page" />
    <!-- <div><NuxtLink to="/">Home page</NuxtLink></div> -->
  </div>
</template>

<script lang="ts">
import { Colors } from '~/utils/generic'
export default defineNuxtComponent({
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors
    }
  }
  // layout: 'blog' // you can set a custom layout for the error page
})
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  min-height: calc(100vh - 338px);
}
</style>
