import revive_payload_client_DkeLXomAmf from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IdSPpeugfa from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RWEPhgNgfP from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ExQ0nq6wBV from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_sass@1.78._7g26izov3zm3wgzh5uo4bzhigu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cazH9czj2G from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HecvgAeQgA from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cIZFk5KLHB from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vRGO3XAHj6 from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fLxV23uxpO from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.5.2_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_EKRe6EHmdp from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_kFrW2iGmON from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4__uycv7k6gszdn7vrz2t5lffdpmq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_9BYiHlWWk9 from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4__uycv7k6gszdn7vrz2t5lffdpmq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_g6HhMhaBzM from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_sass@1._ajnsmpii57slhfbfhniclq7gxu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_QM9s2EFAGS from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_sass@1._ajnsmpii57slhfbfhniclq7gxu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_FESEViJG8v from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/.nuxt/nuxt-modernizr/plugin.js";
import switch_locale_path_ssr_lZW38f0L3G from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hAliYPhoXH from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pinia_48xmdi2HHl from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/plugins/pinia.ts";
export default [
  revive_payload_client_DkeLXomAmf,
  unhead_IdSPpeugfa,
  router_RWEPhgNgfP,
  _0_siteConfig_ExQ0nq6wBV,
  payload_client_cazH9czj2G,
  navigation_repaint_client_HecvgAeQgA,
  check_outdated_build_client_cIZFk5KLHB,
  chunk_reload_client_vRGO3XAHj6,
  plugin_vue3_fLxV23uxpO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EKRe6EHmdp,
  titles_kFrW2iGmON,
  defaultsWaitI18n_9BYiHlWWk9,
  siteConfig_g6HhMhaBzM,
  inferSeoMetaPlugin_QM9s2EFAGS,
  plugin_FESEViJG8v,
  switch_locale_path_ssr_lZW38f0L3G,
  i18n_hAliYPhoXH,
  pinia_48xmdi2HHl
]