// Tailwind needs to detect classes at build time
// All runtime generated classes are lost
// We need to be explixit
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names

type Dictionary = { [index: string]: string }

export enum Colors {
    standard = 'standard',
    highlight = 'highlight',
    orange = 'orange',
    lightgray = 'lightgray',
    lightestgray = 'lightestgray',
    darkgray = 'darkgray',
    backgroundgray = 'backgroundgray',
    formred = 'formred',
    formgray = 'formgray',
    braungray = 'braungray',
    springgray = 'springgray',
    antea = 'antea',
    merryar = 'merryar',
    white = 'white',
}

export const ThemeColorsBackground: Dictionary = {
  standard: 'bg-standard',
  highlight: 'bg-highlight',
  orange: 'bg-orange',
  lightgray: 'bg-lightgray',
  lightestgray: 'bg-lightestgray',
  darkgray: 'bg-darkgray',
  backgroundgray: 'bg-backgroundgray',
  formred: 'bg-formred',
  formgray: 'bg-formgray',
  braungray: 'bg-braungray',
  springgray: 'bg-springgray',
  antea: 'bg-antea',
  merryar: 'bg-merryar',
  white: 'bg-white',
}

export const ThemeColorsText: Dictionary = {
  standard: 'text-standard',
  highlight: 'text-highlight',
  orange: 'text-orange',
  lightgray: 'text-lightgray',
  lightestgray: 'text-lightestgray',
  darkgray: 'text-darkgray',
  backgroundgray: 'text-backgroundgray',
  formred: 'text-formred',
  formgray: 'text-formgray',
  braungray: 'text-braungray',
  springgray: 'text-springgray',
  antea: 'text-antea',
  merryar: 'text-merryar',
  white: 'text-white',
}

export interface MenuEntry {
  "path": string,
  "menu-entry": string,
  "title": string,
  "description": string
}

export interface MenuEntries {
  [key: string]: MenuEntry
}