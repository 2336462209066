<template>
  <!-- FIXME: if route property is set, the class attribute is not passed to the rendered html -->
  <nuxt-link v-if="route" :to="localePath(route)">
    <button :class="[background, foreground, marginTop, marginTopTablet, marginTopLaptop, marginTopFullhd]"
            class="laptop:text-slg rounded pointer-events-auto"
    >
      {{ value }}
    </button>
  </nuxt-link>
  <a v-else-if="href" :href="href" :target="target" @click="$emit('click')">
    <button :class="[background, foreground, marginTop, marginTopTablet, marginTopLaptop, marginTopFullhd]"
            class="laptop:text-slg rounded pointer-events-auto"
    >
      {{ value }}
    </button>
  </a>
  <button v-else :class="[background, foreground, marginTop, marginTopTablet, marginTopLaptop, marginTopFullhd]"
          class="laptop:text-slg rounded pointer-events-auto"
          @click="click"
  >
    {{ value }}
  </button>
</template>

<script lang="ts">
  export default {
    name: 'ActionButton',
    inheritAttrs: false,
    customOptions: {}
  }
</script>
<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue';
import { Colors, ThemeColorsBackground, ThemeColorsText } from '@/utils/generic'

const localePath = useLocalePath()

// Props
const props = withDefaults(defineProps<{
  route?: string,
  target?: string,
  href?: string,
  bg?: Colors,
  fg?: Colors,
  value: string,
  marginTop?: string
  marginTopTablet?: string,
  marginTopLaptop?: string,
  marginTopFullhd?: string,
  }>(), {
  route: '',
  target: '_blank',
  href: '',
  bg: Colors.highlight,
  fg: Colors.white,
  value: '',
  marginTop: 'mt-4',
  marginTopTablet: 'md:mt-8',
  marginTopLaptop: 'lg:mt-12',
  marginTopFullhd: ''
})

// Emits
const emit = defineEmits(['click'])

const background = computed( () : string => {
  return ThemeColorsBackground[props.bg]
})
const foreground = computed( () : string => {
  return ThemeColorsText[props.fg]
})

// Methods
function click() {
  emit('click')
}
</script>

<style scoped>

</style>
