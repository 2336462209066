import { default as animationnBomKVisiUMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/animation.vue?macro=true";
import { default as contacts2Vz7iGQKBMMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/contacts.vue?macro=true";
import { default as antea_45stressagpDpYiirkMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/antea-stress.vue?macro=true";
import { default as braun1PRNt7QxjYMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/braun.vue?macro=true";
import { default as consoleSaGgpKe6BWMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/console.vue?macro=true";
import { default as form_45armchairo9pqfLJkMAMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/form-armchair.vue?macro=true";
import { default as indexlB99iNJKhJMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/index.vue?macro=true";
import { default as onestringlqmYH5m2v4Meta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/onestring.vue?macro=true";
import { default as spring_45chairTYZqMKx6WDMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/spring-chair.vue?macro=true";
import { default as featuresXMysvoIByeMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/features.vue?macro=true";
import { default as free_45demo7vakpJj2z5Meta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/free-demo.vue?macro=true";
import { default as how_45to_45startCF6dJAY9TEMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/how-to-start.vue?macro=true";
import { default as indexYg13ChjjiHMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/index.vue?macro=true";
import { default as linkedin_45promotionmarZEZkFm0Meta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/linkedin-promotion.vue?macro=true";
import { default as springchair9ksbpAiZsGMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/media-evolution-in-ecommerce/springchair.vue?macro=true";
import { default as platform0fBpUeicaDMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/platform.vue?macro=true";
import { default as pricingt7w5kwTCrYMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/pricing.vue?macro=true";
import { default as supportE9NqeJOl1FMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/support.vue?macro=true";
import { default as viewer_453d_45arD8UyMDVptUMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/viewer-3d-ar.vue?macro=true";
import { default as component_45stuba8Rv8v5lCbMeta } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuba8Rv8v5lCb } from "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_rreo26z42w47d7x5ck4mknwuau/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "animation___en___default",
    path: "/animation/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/animation.vue").then(m => m.default || m)
  },
  {
    name: "animation___en",
    path: "/en/animation/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/animation.vue").then(m => m.default || m)
  },
  {
    name: "animation___it",
    path: "/it/animazione/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/animation.vue").then(m => m.default || m)
  },
  {
    name: "animation___de",
    path: "/de/animation/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/animation.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en___default",
    path: "/contacts/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en",
    path: "/en/contacts/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___it",
    path: "/it/contattaci/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___de",
    path: "/de/kontaktiere-uns/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "examples-antea-stress___en___default",
    path: "/examples/antea-stress/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/antea-stress.vue").then(m => m.default || m)
  },
  {
    name: "examples-antea-stress___en",
    path: "/en/examples/antea-stress/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/antea-stress.vue").then(m => m.default || m)
  },
  {
    name: "examples-antea-stress___it",
    path: "/it/esempi/antea-stress/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/antea-stress.vue").then(m => m.default || m)
  },
  {
    name: "examples-antea-stress___de",
    path: "/de/beispielen/antea-stress/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/antea-stress.vue").then(m => m.default || m)
  },
  {
    name: "examples-braun___en___default",
    path: "/examples/braun/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/braun.vue").then(m => m.default || m)
  },
  {
    name: "examples-braun___en",
    path: "/en/examples/braun/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/braun.vue").then(m => m.default || m)
  },
  {
    name: "examples-braun___it",
    path: "/it/esempi/braun/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/braun.vue").then(m => m.default || m)
  },
  {
    name: "examples-braun___de",
    path: "/de/beispielen/braun/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/braun.vue").then(m => m.default || m)
  },
  {
    name: "examples-console___en___default",
    path: "/examples/console/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/console.vue").then(m => m.default || m)
  },
  {
    name: "examples-console___en",
    path: "/en/examples/console/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/console.vue").then(m => m.default || m)
  },
  {
    name: "examples-console___it",
    path: "/it/esempi/console/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/console.vue").then(m => m.default || m)
  },
  {
    name: "examples-console___de",
    path: "/de/beispielen/console/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/console.vue").then(m => m.default || m)
  },
  {
    name: "examples-form-armchair___en___default",
    path: "/examples/form-armchair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/form-armchair.vue").then(m => m.default || m)
  },
  {
    name: "examples-form-armchair___en",
    path: "/en/examples/form-armchair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/form-armchair.vue").then(m => m.default || m)
  },
  {
    name: "examples-form-armchair___it",
    path: "/it/esempi/form-armchair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/form-armchair.vue").then(m => m.default || m)
  },
  {
    name: "examples-form-armchair___de",
    path: "/de/beispielen/form-armchair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/form-armchair.vue").then(m => m.default || m)
  },
  {
    name: "examples___en___default",
    path: "/examples/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/index.vue").then(m => m.default || m)
  },
  {
    name: "examples___en",
    path: "/en/examples/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/index.vue").then(m => m.default || m)
  },
  {
    name: "examples___it",
    path: "/it/esempi/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/index.vue").then(m => m.default || m)
  },
  {
    name: "examples___de",
    path: "/de/beispielen/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/index.vue").then(m => m.default || m)
  },
  {
    name: "examples-onestring___en___default",
    path: "/examples/onestring/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/onestring.vue").then(m => m.default || m)
  },
  {
    name: "examples-onestring___en",
    path: "/en/examples/onestring/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/onestring.vue").then(m => m.default || m)
  },
  {
    name: "examples-onestring___it",
    path: "/it/esempi/onestring/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/onestring.vue").then(m => m.default || m)
  },
  {
    name: "examples-onestring___de",
    path: "/de/beispielen/onestring/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/onestring.vue").then(m => m.default || m)
  },
  {
    name: "examples-spring-chair___en___default",
    path: "/examples/spring-chair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/spring-chair.vue").then(m => m.default || m)
  },
  {
    name: "examples-spring-chair___en",
    path: "/en/examples/spring-chair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/spring-chair.vue").then(m => m.default || m)
  },
  {
    name: "examples-spring-chair___it",
    path: "/it/esempi/spring-chair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/spring-chair.vue").then(m => m.default || m)
  },
  {
    name: "examples-spring-chair___de",
    path: "/de/beispielen/spring-chair/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/examples/spring-chair.vue").then(m => m.default || m)
  },
  {
    name: "features___en___default",
    path: "/features/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___en",
    path: "/en/features/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___it",
    path: "/it/funzionalita/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___de",
    path: "/de/eigenschaften/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "free-demo___en___default",
    path: "/free-demo/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/free-demo.vue").then(m => m.default || m)
  },
  {
    name: "free-demo___en",
    path: "/en/free-demo/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/free-demo.vue").then(m => m.default || m)
  },
  {
    name: "free-demo___it",
    path: "/it/demo-gratuita/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/free-demo.vue").then(m => m.default || m)
  },
  {
    name: "free-demo___de",
    path: "/de/free-demo/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/free-demo.vue").then(m => m.default || m)
  },
  {
    name: "how-to-start___en___default",
    path: "/how-to-start/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/how-to-start.vue").then(m => m.default || m)
  },
  {
    name: "how-to-start___en",
    path: "/en/how-to-start/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/how-to-start.vue").then(m => m.default || m)
  },
  {
    name: "how-to-start___it",
    path: "/it/come-iniziare/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/how-to-start.vue").then(m => m.default || m)
  },
  {
    name: "how-to-start___de",
    path: "/de/wie-man-anfangt/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/how-to-start.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "linkedin-promotion___en___default",
    path: "/linkedin-promotion/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/linkedin-promotion.vue").then(m => m.default || m)
  },
  {
    name: "linkedin-promotion___en",
    path: "/en/linkedin-promotion/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/linkedin-promotion.vue").then(m => m.default || m)
  },
  {
    name: "linkedin-promotion___it",
    path: "/it/linkedin-promotion/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/linkedin-promotion.vue").then(m => m.default || m)
  },
  {
    name: "linkedin-promotion___de",
    path: "/de/linkedin-promotion/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/linkedin-promotion.vue").then(m => m.default || m)
  },
  {
    name: "media-evolution-in-ecommerce-springchair___en___default",
    path: "/media-evolution-in-ecommerce/springchair/",
    meta: springchair9ksbpAiZsGMeta || {},
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/media-evolution-in-ecommerce/springchair.vue").then(m => m.default || m)
  },
  {
    name: "media-evolution-in-ecommerce-springchair___en",
    path: "/en/media-evolution-in-ecommerce/springchair/",
    meta: springchair9ksbpAiZsGMeta || {},
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/media-evolution-in-ecommerce/springchair.vue").then(m => m.default || m)
  },
  {
    name: "media-evolution-in-ecommerce-springchair___it",
    path: "/it/media-evolution-in-ecommerce/springchair/",
    meta: springchair9ksbpAiZsGMeta || {},
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/media-evolution-in-ecommerce/springchair.vue").then(m => m.default || m)
  },
  {
    name: "media-evolution-in-ecommerce-springchair___de",
    path: "/de/media-evolution-in-ecommerce/springchair/",
    meta: springchair9ksbpAiZsGMeta || {},
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/media-evolution-in-ecommerce/springchair.vue").then(m => m.default || m)
  },
  {
    name: "platform___en___default",
    path: "/platform/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "platform___en",
    path: "/en/platform/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "platform___it",
    path: "/it/piattaforma/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "platform___de",
    path: "/de/plattform/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en___default",
    path: "/pricing/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en",
    path: "/en/pricing/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___it",
    path: "/it/prezzi/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___de",
    path: "/de/preisen/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "support___en___default",
    path: "/support/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/en/support/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___it",
    path: "/it/assistenza/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___de",
    path: "/de/support/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "viewer-3d-ar___en___default",
    path: "/viewer-3d-ar/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/viewer-3d-ar.vue").then(m => m.default || m)
  },
  {
    name: "viewer-3d-ar___en",
    path: "/en/viewer-3d-ar/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/viewer-3d-ar.vue").then(m => m.default || m)
  },
  {
    name: "viewer-3d-ar___it",
    path: "/it/visualizzatore-3d-ar/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/viewer-3d-ar.vue").then(m => m.default || m)
  },
  {
    name: "viewer-3d-ar___de",
    path: "/de/3d-ar-viewer/",
    component: () => import("/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/pages/viewer-3d-ar.vue").then(m => m.default || m)
  },
  {
    name: component_45stuba8Rv8v5lCbMeta?.name,
    path: "/sitemap.xml",
    component: component_45stuba8Rv8v5lCb
  },
  {
    name: component_45stuba8Rv8v5lCbMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stuba8Rv8v5lCb
  },
  {
    name: component_45stuba8Rv8v5lCbMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stuba8Rv8v5lCb
  },
  {
    name: component_45stuba8Rv8v5lCbMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stuba8Rv8v5lCb
  },
  {
    name: component_45stuba8Rv8v5lCbMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stuba8Rv8v5lCb
  }
]